import dayjs from 'dayjs';

import {
  CommercialStatusEnum, LegalStatusEnum, UserRole,
} from '~/api/account/accountApiTypes';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { userAlertActions } from '~/feature/userAlert/userAlert.slice';
import { environments } from '~/lib/const';
import { createAppThunk } from '~/store';

const AUTHOR_NOTIFICATION_ABOUT_ABONNEMENT_NAME_V2 = 'new_author_notification_about_abonnement_v2';
const ROAYL100_STORAGE_KEY = 'royal_100';

const checkUserInLocalStorage = (userSlug: string, key: string) => {
  const userSlugsJSON = localStorage.getItem(key);

  if (!userSlugsJSON) return false;

  const userSLugs = JSON.parse(userSlugsJSON);

  return userSLugs.includes(userSlug);
};

const addUserSlugToLocalStorage = (userSlug: string, key: string) => {
  const userSlugsJSON = localStorage.getItem(key);
  let userSlugs: string[];

  if (!userSlugsJSON) {
    userSlugs = [userSlug];
  } else {
    userSlugs = JSON.parse(userSlugsJSON);
    userSlugs.push(userSlug);
  }

  localStorage.setItem(key, JSON.stringify(userSlugs));
};

interface Roayl100Payload {
  username: String;
  count: number;
  lastShowDate: string;
}

const addRoyal100PayloadToLocalStorage = (payload: Roayl100Payload) => {
  localStorage.setItem(ROAYL100_STORAGE_KEY, JSON.stringify(payload));
};

const getRoayl100PayloadFromLocalStorage = (): Roayl100Payload | null => {
  const payloadJSON = localStorage.getItem(ROAYL100_STORAGE_KEY);

  if (!payloadJSON) return null;

  return JSON.parse(payloadJSON);
};

export const showAlert = createAppThunk(
  'showAlert',
  async (_, thunkAPI) => {
    if (!environments.isClient) return;
    const isLoggedIn = isLoggedInSelector(thunkAPI.getState());
    if (!isLoggedIn) return;
    const currentDate = dayjs();
    const expiredDate = dayjs('2023-09-20');
    const isExpiredTime = expiredDate.diff(currentDate) < 0;

    const { user } = userSelector(thunkAPI.getState());

    const isShowAbonnementModal = !isExpiredTime && user.commercialStatus === CommercialStatusEnum.accepted
      && !checkUserInLocalStorage(user.username, AUTHOR_NOTIFICATION_ABOUT_ABONNEMENT_NAME_V2)
      && (user.legalStatus === LegalStatusEnum.IndividualEntrepreneur || user.legalStatus === LegalStatusEnum.SelfEmployed);

    if (isShowAbonnementModal) {
      addUserSlugToLocalStorage(user.username, AUTHOR_NOTIFICATION_ABOUT_ABONNEMENT_NAME_V2);
      thunkAPI.dispatch(userAlertActions.toggleShowDefaultAuthorModalAboutAbonnement(true));
    }

    const payload = getRoayl100PayloadFromLocalStorage();
    const isAuthor = user?.role === UserRole.author;
    const canShowRoayl100Payload = isAuthor && (!payload || (payload.count < 2 && dayjs().diff(payload.lastShowDate, 'day') > 5));
    if (canShowRoayl100Payload) {
      addRoyal100PayloadToLocalStorage({
        username: user.username,
        count: payload ? payload.count + 1 : 1,
        lastShowDate: dayjs().format(),
      });
      thunkAPI.dispatch(userAlertActions.toggleShowRoyal100(true));
    }
  },
);
