import React from 'react';
import styled from 'styled-components';

import Modal from '~/atomic/molecula/Modal';
import { PageTitleStyle, TextRegStyle } from '~/atomic/Typography';
import { userAlertSelector } from '~/feature/userAlert/userAlert.selector';
import { userAlertActions } from '~/feature/userAlert/userAlert.slice';
import { useAppDispatch, useAppSelector } from '~/store';

export const Royal100Modal = () => {
  const dispatch = useAppDispatch();
  const { iseShowRoyal100 } = useAppSelector(userAlertSelector);

  const closeModal = () => {
    dispatch(userAlertActions.toggleShowRoyal100(false));
  };
  return (
    <Modal
      open={iseShowRoyal100}
      onCancel={closeModal}
      width="560px"
    >
      <SCContent>
        <SCTitle>
          Присоединяйтесь к программе
          <SCLink href="/post/aktsiya-avtoru---100-6194" target="_blank"> "Автору  100%"</SCLink>
        </SCTitle>
        <SCDescription>
          100% роялти - ведь вы этого достойны!
        </SCDescription>
      </SCContent>
    </Modal>
  );
};

const SCContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

const SCTitle = styled.h3`
  ${PageTitleStyle};
`;

const SCDescription = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
`;

const SCButtonsWrap = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

const SCLink = styled.a`
  ${PageTitleStyle};
  color: var(--primary-color);
`;
