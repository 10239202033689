import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const userAlertSlice = createSlice({
  name: 'userAlert',
  initialState: {
    isShowAuthorModalAboutAbonnement: false,
    isShowDefaultAuthorModalAboutAbonnement: false,
    iseShowRoyal100: false,
  },
  reducers: {
    toggleShowAuthorModalAboutAbonnement: (state, action: PayloadAction<boolean>) => {
      state.isShowAuthorModalAboutAbonnement = action.payload;
    },
    toggleShowDefaultAuthorModalAboutAbonnement: (state, action: PayloadAction<boolean>) => {
      state.isShowDefaultAuthorModalAboutAbonnement = action.payload;
    },
    toggleShowRoyal100: (state, action: PayloadAction<boolean>) => {
      state.iseShowRoyal100 = action.payload;
    },
  },
});

export const { reducer: userAlertReducer, actions: userAlertActions } = userAlertSlice;
